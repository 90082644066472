import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string, countryCode?: number | string): string | number {
    if (!phoneValue) {
      return '';
    }
    const ayt = new AsYouType();
    try {
      ayt.input(countryCode + '' + phoneValue + '');
      return ayt.getNumber().formatNational();
    } catch (error) {
      // console.log(error);
      // return phoneValue;
    }
  }
}
