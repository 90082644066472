import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
    path: 'enter-message',
    loadChildren: () => import('./pages/enter-message/enter-message.module').then( m => m.EnterMessagePageModule)
  },
  {
    path: 'saved-messages',
    loadChildren: () => import('./pages/saved-messages/saved-messages.module').then( m => m.SavedMessagesPageModule)
  },
  {
    path: 'edit-message',
    loadChildren: () => import('./pages/edit-message/edit-message.module').then( m => m.EditMessagePageModule)
  },
  {
    path:'**', redirectTo:'/'
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
